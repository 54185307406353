<template>
    <div :class="clientBreakpoint.width < 730 ? 'px-3 mx-3' : 'px-10 mx-10'">
        <!-- Title -->
        <v-row>
            <v-col>
                <v-card-text primary-title class="px-0 mt-4 mb-0 title text-center font-weight-bold text-wrap">
                    Do you service my address?
                </v-card-text>
            </v-col>
        </v-row>

        <v-divider v-if="website"></v-divider>

        <!-- List of all Roll Off size -->
        <v-form v-if="!website" ref="searchForm" lazy-validation @submit.prevent>
            <v-text-field v-model="zipCode" placeholder="Enter zip code..." outlined dense color="secondary"
                class="rounded-lg">
                <template v-slot:prepend-inner>
                    <v-icon color="secondary">mdi-map-marker</v-icon>
                </template>
                <template v-slot:append-outer>
                    <v-slide-x-transition leave-absolute>
                        <v-btn outlined color="secondary" height="40px" @click="sendZipCode" :loading="loading"
                            class="mt-0 rounded-lg text-subtitle-1 text-capitalize custom-shadow">
                            <strong>Search</strong>
                        </v-btn>
                    </v-slide-x-transition>
                </template>
                <template v-slot:append>
                    <v-icon v-if="zipCode" color="secondary" @click="clearInput">mdi-close-circle-outline</v-icon>
                </template>
            </v-text-field>
        </v-form>

        <div v-else>

            <!-- Website -->
            <v-row v-if="website">
                <v-col class="d-flex flex-column justify-center">
                    <v-card-text primary-title class="px-0 mt-4 mb-0 title text-center font-weight-bold text-wrap">
                        We got a website !!!
                    </v-card-text>
                    <v-btn :href="website" outlined color="secondary" target="_blank" class="text-capitalize text-subtitle-1 font-weight-bold">
                        <v-icon class="mr-2">mdi-web</v-icon>
                        <span class="text-lowercase">{{ website }}</span>
                    </v-btn>
                </v-col>
            </v-row>

            <!-- If no website -->

            <v-row v-else>
                <v-col>
                    <v-card-text primary-title class="px-0 mt-4 mb-0 title text-center font-weight-bold text-wrap">
                        No we do not !!!
                    </v-card-text>
                </v-col>
            </v-row>

        </div>
    </div>
</template>

<script>
// import icon from "../utility/SvgLoader";
import { mapActions, mapState, mapWritableState } from 'pinia';
import { useRollOffStore } from '../../../store/rolloffdumpster';
import { useDumpsterCommercialStore } from "@/store/dumpstercommercial";
import { useIndexStore } from '@/store/index';
import { useUserStore } from '@/store/user';
import storefront from '@/plugins/storefront';
import i18n from '@/i18n';
import axios from 'axios';

export default {
    name: "SiteZipCode",
    components: {
        // icon,
    },

    props: {
        recommended: Boolean,
        newstoreapi: Boolean,
    },

    data() {
        return {
            i18n: i18n,
            serviceFormErrors: false,
            defaultColor: "#1F528F",
            zipCode: "",
            website: "",
        }
    },

    watch: {
        "dumpsterSelected": function (newVal, oldVal) {
            if (newVal != oldVal) {

                if (this.dumpsterSelected) {

                    this.dumpsterSelected.qty = 1;
                    this.updateViewComponent("RollOffForm");

                }
                for (let index = 0; index < this.rollOffList.length; index++) {

                    if (this.dumpsterSelected.size.text == this.rollOffList[index].size.text) {

                        this.rollOffList[index].qty = 1;

                    } else {

                        this.rollOffList[index].qty = 0;

                    }
                }
            }
        },
    },

    computed: {

        ...mapState(useRollOffStore, ['rollOffList']),
        ...mapState(useIndexStore, ['formRules']),
        ...mapWritableState(useDumpsterCommercialStore, ["dumpsterSelected"]),
        ...mapWritableState(useRollOffStore, ["serviceCardId"]),
        ...mapWritableState(useIndexStore, ['component', "formValid", "formErrors", "loading", "clientBreakpoint", "servicefromquote", "currentTabComponent"]),
        ...mapWritableState(useUserStore, ["form"]),

    },

    methods: {
        ...mapActions(useIndexStore, ['updateViewComponent', 'sendHttpRequestQuote']),

        sendZipCode() {
            this.loading = true;
            axios.post('https://pws--aresdev.sandbox.my.salesforce-sites.com/wcn/services/apexrest/ServiceAvailability', {
                zipcode: this.zipCode
            })
                .then(response => {
                    const { DistrictDetail } = response.data;
                    if (DistrictDetail) {
                        this.website = DistrictDetail?.website;
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        clearInput() {
            this.zipCode = '';
        },
        // Validate for buttons
        validateWithService() {

            this.loading = true;
            storefront.sendInfoWithService();

        },
        validate() {

            this.loading = true;
            var vm = this;
            if (vm.validateForm()) {

                vm.sendHttpRequestQuote()
                vm.updateViewComponent('RollOffForm')
                vm.loading = false;

            } else {

                vm.loading = false;

            }
        },

        validateForm() {

            if (!this.serviceCardId.length && this.rollOffList.every(function (v) { return v.qty == 0 })) {

                this.serviceFormErrors = true;
                this.formValid = false;
                this.formErrors = [];
                this.formErrors.push(i18n.t("missingField"));
                return this.$refs.form.validate() && !this.formErrors.length;

            }
            return this.$refs.form.validate();
        },
    },


}
</script>

<style scoped>
::v-deep .v-input__append-outer {
    margin-top: 0px !important;
}
</style>