<template>
  <div fluid>
    <v-card outlined>
    <!-- class="offset-lg1 lg10 offset-xl1 xl10" -->
    <v-flex>
        <component class="mb-10 mt-1"
                v-bind:is="currentTabComponent" :name="name"
                :newstoreapi="newstoreapi" :service="service" :assigntodistrict="assigntodistrict" :phone="phone" :canada="canada" :country="country" :terms="terms" :privacy="privacy" :splitter="splitter" :lang="lang" :header="header" :primary="primary" :secondary="secondary"
                :serviceswantedwithpricing="serviceswantedwithpricing" :recommended="recommended">
        </component>
    </v-flex>

    </v-card>
    <!-- <v-card outlined>
        <v-alert class="grey lighten-3 ma-0" elevation ="3">
          <v-flex class="lg12 xl12">
          <CustomerServiceBar class="py-0" :phone="phone"></CustomerServiceBar>
        </v-flex>
      </v-alert>
    </v-card> -->
  </div>
</template>

<script>
import SiteZipCode from "../sitezipcode/view/SiteZipCode.vue"
import CustomerServiceBar from "@/components/requestquote/footer/CustomerServiceBar"
import ThankyouExplore from "@/components/requestquote/view/thankyou/ThankyouExplore"
import ProcessingOrder from "@/components/requestquote/view/thankyou/ProcessingOrder"

import { mapWritableState } from 'pinia';
import { useIndexStore } from '@/store/index';
import { useUserStore } from '@/store/user';
import i18n from "@/i18n";
// import ButtonsView from "../sfc/ButtonsView.vue";


export default {
  name:"SiteZipCodeView",
  components:{
      SiteZipCode,
      ThankyouExplore,
      CustomerServiceBar,
      ProcessingOrder,
      // ButtonsView,
  },
  props:{
    service: String,
    assigntodistrict: Number,
    storefrontDistrictCodeOverride: Number,
    lang: String,
    phone: String,
    country: String,
    terms: String,
    privacy: String,
    name: String,
    splitter: Boolean,
    canada: Boolean,

    header: String,
    primary: String,
    secondary: String,
    newstoreapi: Boolean,
    serviceswantedwithpricing: Boolean,
    recommended: Boolean,
  },
  computed: {
  ...mapWritableState(useIndexStore, {
    component: 'component',
    currentTabComponent: 'currentTabComponent',
    newStoreApi: 'newStoreApi',
    storefrontDistrictCodeOverrideEdit: 'storefrontDistrictCodeOverride',
    }),
    ...mapWritableState(useUserStore, {

    form: 'form',

    })
  },
  data: () => ({
    i18n: i18n,
  }),
  created() {
    i18n.locale = this.lang;
    if(this.newstoreapi){
      this.newStoreApi = this.newstoreapi;
    }
    this.storefrontDistrictCodeOverrideEdit = this.storefrontDistrictCodeOverride;
  },
}

</script>

<style scoped>
::v-deep button {
text-transform: none !important;
}
::v-deep .v-input__icon.v-input__icon--prepend-inner i {
font-size: 28px !important;
color: #1f528f;
}
::v-deep .input-label {
font-size: 14px !important;
word-break: normal !important;
color: black !important;
border-radius: 10px !important;
}
::v-deep .label-form{
font-size:16px;
font-weight: 500;
}
::v-deep .congrats {
font-size: 32px;
}
::v-deep .text-quote {
font-size: 22px;
}
::v-deep .text-quote-ty {
font-size: 20px;
}
::v-deep .btn-label {
font-size: 20px !important;
line-height: 25px;
word-break: normal !important;
justify-content: center; /* horizontally centered */
}
::v-deep .btn-explore {
background-color: #153F70 !important;
color:white !important;
}
::v-deep .blue-default-bg{
  background-color: #F0F7FE !important;
}
::v-deep .blue-hover-bg{
  background-color: #DAE7F5 !important;
}
::v-deep .blue-selected-bg{
  background-color: #1F528F !important;
}
::v-deep .recommended{
  border: 2px solid #148432 !important;
}
::v-deep .recommended-position{
  z-index: 1;
  position: absolute;
  top:-3%;
  right:10%
}
::v-deep .numberEdit{
  text-align: center;
  border: 2px solid white;
  width: 40px;

}
@media screen and (max-width: 375px)  {
::v-deep .btn-label{
  font-size: 16px !important;
}
}
@media screen and (max-width: 475px)  {

::v-deep .label-sqr-btn {
  padding-top: 12px !important ;
  font-size: 16px !important;

}
::v-deep .service-bar-span{
  font-size:16px !important ;
}
::v-deep .v-list-item__title.text-h6{
  font-size:16px !important;
}
::v-deep .text-quote {
  font-size: 16px;
}
::v-deep .text-quote-ty {
  font-size: 16px;
}
::v-deep .congrats {
  font-size: 28px;
}
}
</style>